const input = {
    username: 'Nome de usuário',
    password: 'Senha',
    email: 'E-mail',
    phone_number: 'Número de telefone',
    confirm_password: 'Confirme a senha',
    search_region_code: 'Pesquisar código de região',
    backup_code: 'Código de backup',
};
export default Object.freeze(input);
